+'use strict';

window.Components = function (Components) {
    // how long should we wait until we declare the request dead & inform the user?
    var REQUEST_TIMEOUT = 10000,
        TIMEOUT_REASON = "Timeout",
        requestRetryCounter = 1,
        MAX_RETRIES = 2,
        QUEUE_SEPARATOR = "_"; // Queue separator for the queueId, used to ensure that IDs are unique.
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content
        class MediaLoaderBase extends Components.Extension {
            constructor(component, extensionChannel, reloadDelegates) {
                super(...arguments);
                this.name += "@" + component.getServerName();
                this.registerCntr = 0;
                this.socketIsOpen = false;
                this.sentCommands = {};
                this.cache = {}; // holds the last requests, RqID is the key, start-index, number of items and optional the deffered are
                // the arguments

                this.requests = {};
                this.requestQueue = []; // holds the IDs for the requests.

                this.currentRequest = NO_REQUEST_ID; // contains packets that are ready to be dispatched to the UI (large caches will be dispatched in chunks)

                this.dispatchQueue = [];
                this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, this._handleConnectionEstablished.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnClosed, this._handleConnectionClosed.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, this._handleEventReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, this._handleResultReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, this._handleResultErrorReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResetMediaApp, this._handleResetMediaApp.bind(this)); // reuse potential preexisting delegates, e.g. used for queue loader.

                this.reloadDelegates = reloadDelegates;
            }

            // -------------------------------------------------------
            //            Handling Events
            // -------------------------------------------------------
            _handleConnectionEstablished() {
                this.socketIsOpen = true; // when the connection is reopened, launch all the requests again!

                if (this.currentRequest !== NO_REQUEST_ID) {
                    // never launch a request for NO_REQUEST_ID
                    var rq = this.currentRequest;
                    this.currentRequest = NO_REQUEST_ID;

                    this._sendRequest(rq);
                }

                this._dispatchReloadEvent(MusicServerEnum.ReloadCause.RESUMED);
            }

            _handleConnectionClosed() {
                this.socketIsOpen = false;
            }

            _handleEventReceived(evId, event) {
                try {
                    if (this._doesHandleEvent(event)) {
                        this._processEvent(event);
                    }
                } catch (exc) {
                    console.error(this.name + " could not process the event " + JSON.stringify(event));
                    console.error(exc);
                }
            }

            _handleResultReceived(evId, result) {
                try {
                    if (this._doesHandleResult(result)) {
                        var resultObjects = result.data;
                        Debug.Communication && CommTracker.commFin(CommTracker.Transport.AUDIO_SOCKET, result.command, false, resultObjects);

                        for (var i = 0; i < resultObjects.length; i++) {
                            const limitFromCommand = result.command.split("/").pop();
                            const queueId = `${resultObjects[i].id}${QUEUE_SEPARATOR}${limitFromCommand}`;
                            this._processResult({...resultObjects[i], queueId}, result.oldCommand, result.command);
                        }
                    }
                } catch (exc) {
                    console.error(this.name + " could not process the result of '" + result.oldCommand + "! ");
                    console.error(exc);
                }
            }

            /**
             * Will determine if the result received is the one of the current request, if so, it will reject the
             * promises and ensure that the loader won't get stuck.
             * @param evId
             * @param result    the result object containing at least the command & the reason
             * @private
             */
            _handleResultErrorReceived(evId, result) {
                Debug.Media.Loader && console.log(this.name + ": _handleResultErrorReceived: result: " + JSON.stringify(result) + ", evId: ", evId);

                try {
                    var currRqObj = this.requests[this.currentRequest];

                    if (currRqObj && currRqObj.command === result.command) {
                        console.error(this.name, "The current requests command did fail! " + currRqObj.command, result);
                        Debug.Communication && currRqObj.command && CommTracker.commFin(CommTracker.Transport.AUDIO_SOCKET, currRqObj.command, true); // the request failed due to an error, ensure the timeout won't hit here.

                        this._clearResponseTimeout();

                        if (result.reason === TIMEOUT_REASON && ++requestRetryCounter <= MAX_RETRIES) {
                            var rq = this.currentRequest;
                            this.currentRequest = NO_REQUEST_ID;
                            console.error("Retrying timed out request.");

                            this._sendRequest(rq);
                        } else {
                            console.error(this.name, "_handleResultErrorReceived: not a timeout, fail it! reasion is " + result.reason);

                            if (this._shouldIgnoreError(currRqObj, result)) {
                                Debug.Media.Loader && console.log(this.name, " ---> IGNORE Error");
                                // error handling performed in subclass, nothing to do here!

                            } else {
                                this._processRequestError(result);

                                this._cancelCurrentRequest(result.reason); // go on


                                console.error(this.name, "_handleResultErrorReceived: try to send the next item in the queue (if available)", this.requestQueue);

                                if (this.requestQueue.length > 0) {
                                    this._sendRequest(this.requestQueue.shift());
                                }
                            }

                        }
                    } else {
                        var failedRq = null;
                        Object.keys(this.requests).some(function (rqId) {
                            if (this.requests[rqId].command === result.command) {
                                failedRq = this.requests[rqId];
                                return true;
                            }
                            return false;
                        }.bind(this));

                        if (this._shouldIgnoreError(failedRq, result)) {
                            console.warn(this.name, "_handleResultErrorReceived: Ignoring failed request. " + result.command);
                        } else {
                            if (currRqObj) {
                                console.error(this.name, "_handleResultErrorReceived: Commands did not match! " + currRqObj.command + " !== " + result.command);
                            } else {


                                if (failedRq) {
                                    console.error(this.name, "_handleResultErrorReceived: The failed request isn't the current request (" + this.currentRequest + "), but there is one in the requests object!", this.requests);
                                } else {
                                    console.error(this.name, "_handleResultErrorReceived: Couldn't find currReqObj for currentRequest: " + this.currentRequest, this.requests);
                                }
                            }
                        }
                    }
                } catch (exc) {
                    console.error(this.name + "_handleResultErrorReceived: could not process the error message '" + JSON.stringify(result) + "! ");
                    console.error(exc);
                }
            }

            /**
             * Used primarily for development purposes.
             * @param rqObj the request object that failed
             * @param error the error returned
             * @returns {boolean} if true, it won't furtherly process it (cancel the request and so on).
             * @private
             */
            _shouldIgnoreError(rqObj, error) {
                return false;
            }

            _handleResetMediaApp(event, reason) {
                Debug.Media.Loader && console.log(this.name + ": ResetMediaApp received. (" + JSON.stringify(reason) + ") Invalidate all caches"); // invalidate everything we've got in the cache.

                var cacheKeys = Object.keys(this.cache),
                    cause = reason && reason.stopped ? MusicServerEnum.ReloadCause.STOPPED : null;

                for (var i = 0; i < cacheKeys.length; i++) {
                    this.invalidateContentCachesOf(cacheKeys[i], cause);
                }
            }

            // -------------------------------------------------------
            //            Public Methods Events
            // -------------------------------------------------------
            getAcquireCommand() {
                throw "getAcquireCommand has to be implemented in the subclass! " + this.name;
            }

            /**
             * Creates the argument list for a given request. Usually id/start/nItems - but for playlists
             * and room-favorites this differs.
             * @param request - used to create the arguments list.
             * @returns {string} the arguments appended to the aquireCommand
             */
            getAcquireCommandArguments(request) {
                return request.id + "/" + request.start + "/" + request.nItems;
            }

            /**
             * Like request content, but doesn't load any further than the first nItems requested.
             * @param id the item whichs content is to be loaded
             * @param nItems the number of items requested, won't load more than that.
             * @param [mediaTypeDetails] the media type details
             * @param [noCache] if true, nothing will be used from the cache.
             * @result returns a promise that dispatches the results.
             */
            requestContentBatch(id, nItems, mediaTypeDetails, noCache) {
                Debug.Media.Loader && console.log(this.name + ": requestContentBatch of " + id + "  - " + nItems + " items, noCache: " + !!noCache);
                return this._acquireContent(id, nItems, mediaTypeDetails, true, noCache);
            }

            /**
             * Requests the content of a certain directory specified by the id. it will return the data at once
             * if it's cached already, if not, it will download and dispatch the rest in nItems portions.
             * @param id the item whos content is of interest
             * @param nItems the number of items the UI wants to receive in portions
             * @param [mediaTypeDetails] the media type details
             * @result Returns a promise that dispatches the results
             */
            requestContent(id, nItems, mediaTypeDetails) {
                Debug.Media.Loader && console.log(this.name + ": requestContent of " + id + " (" + nItems + " at a time)");
                return this._acquireContent(id, nItems, mediaTypeDetails, false);
            }

            _acquireContent(id, nItems, mediaTypeDetails, limited, noCache) {
                var result = {};
                var deferred = Q.defer();
                const queueId = `${id}${QUEUE_SEPARATOR}${nItems}`;
                Debug.Media.Loader && console.log(this.name + ": _acquireContent of " + id + ", queueId: " + queueId + ", items" + nItems + ", limited: " + !!limited + ", noCache: " + !!noCache);
                Debug.Media.Loader && console.log(this.name + ":    mediaTypeDetails: " + JSON.stringify(mediaTypeDetails), getStackObj());
                var cache = noCache ? null : this._getFromCache(queueId);

                if (cache && cache.totalitems === cache.items.length) {
                    // yey, it's cached, return it!
                    Debug.Media.Loader && console.log("          ", "the content of " + id + " is fully cached, dispatch part by part"); // never dispatch all at once - it corrupts the UI

                    result.data = this._splitAndDispatchSeparated(deferred, cache, true, nItems, limited);
                } else {
                    Debug.Media.Loader && console.log("          ", (cache ? "not all of " : "nothing of ") + id + " is cached");
                    var startIdx = 0;

                    if (this._prepareRequest(id, queueId, startIdx, nItems, deferred, limited)) {
                        Debug.Media.Loader && console.log("          ", "created a new requested for " + id);

                        var request = this._getRequest(queueId); // no preexisting request, proceed.


                        if (cache) {
                            this._handleUnfinishedRequest(request, cache);

                            result.data = this._splitAndDispatchSeparated(deferred, cache, false, nItems, limited);
                        } else {
                            this._sendRequest(queueId, mediaTypeDetails);
                        }
                    } else {
                        Debug.Media.Loader && console.log("          ", "request exists in queue, put the existing request to the front of the queue"); // first of all, make sure this id is up front in the Queue

                        var currQueuePos = this.requestQueue.indexOf(queueId);

                        if (currQueuePos > 1) {
                            this.requestQueue.splice(currQueuePos, 1);
                            this.requestQueue.unshift(queueId);
                        }

                        if (cache) {
                            Debug.Media.Loader && console.log("          ", "the content of " + id + " was partially cached, return"); // no need to send anything. dispatch what we've got.

                            result.data = this._splitAndDispatchSeparated(deferred, cache, false, nItems, limited);
                        }
                    }
                }

                result.promise = deferred.promise;
                return result; //deferred.promise;
            }

            /**
             * Called whenever the cache needs to be invalidated (e.g. when the favorites where edited). Will inform
             * all registered listeners to reload their data.
             * @param id            the id of the element who's cache is to be invalidated.
             * @param reason  why where the contens invalidated? used for the reloadEvent
             * @param remove  should the view reload the content or should it remove itself from the screen.
             * @param invalidateParents if provided, the parents containing those IDs are also invalidated.  e.g. follow/unfollow
             * @param keepChildCache if provided, the child content provided is NOT invalidated. e.g. follow/unfollow
             */
            invalidateContentCachesOf(id, reason, remove, invalidateParents, keepChildCache) {
                Debug.Media.Loader && console.log(this.name + ": invalidateContentCachesOf: " + id); // not only the item with the id, but also all cached content of this id needs to be invalidated too.
                // iterative approach, recursive could have performance issues

                var invalidateIds = [id],
                    currId,
                    loopFn = function (obj) {
                        // only push items onto the stack that are in the cache
                        if (this.component.isFileTypeBrowsable(obj[MusicServerEnum.Event.FILE_TYPE]) && Object.values(this.cache).some(cacheItem => cacheItem.id === obj[MusicServerEnum.Event.ID])) { // with unique IDs, we're not using keys anymore but the actual ID which is stored in the cache entry.
                            const actualId = Object.keys(this.cache).find(cacheKey => this.cache[cacheKey].id === obj[MusicServerEnum.Event.ID]);
                            invalidateIds.push(actualId);
                        }
                    }.bind(this); // second, invalidate the caches of each id. stick with for, to get a clean stack trace

                if (invalidateParents) {
                    // check if the id to invalidate also affects a parent that contains it
                    invalidateIds = invalidateIds.concat(this._lookupParentsFromCache(id));
                } // second, invalidate the caches of each id. stick with for, to get a clean stack trace


                for (var i = 0; i < invalidateIds.length; i++) {
                    // get the next id to invalidate
                    currId = invalidateIds[i]; // check if there's sth cached for this id

                    var cacheResult = this._getFromCache(currId);

                    if (cacheResult) {
                        // if so, iterate over the items & push their ids onto the invalidate stack
                        // ignore the child ids if keepChildCache is set.
                        !keepChildCache &&cacheResult.items.forEach(loopFn); // invalidate the cache for the current id

                        this._invalidateCacheFor(currId);
                    }
                } // third, notify listeners


                for (i = 0; i < invalidateIds.length; i++) {
                    currId = invalidateIds[i]; // the remove property has only been explicitly provided for the ID in question, don't pass on to others.

                    this._dispatchReloadEvent(reason, remove && currId === id, currId);
                }
            }

            /**
             * This method simply tells the library to prefetch and cache the first nItems of the contentOld.
             * It does not download any further content of the specified item.
             * @param id the item whos content is to be prefetched
             * @param nItems
             */
            prefetchContent(id, nItems) {
                Debug.Media.Loader && console.log(this.name + ": prefetchContent first " + nItems + " of " + id);
                var startIdx = 0; // prefetching always starts with 0
                // first of all, check if the requested info isn't cached already

                var cache = this._getFromCache(id); // if there is more to be prefetched than we've got, "invalidate" the cache.


                if (cache && cache.items.length < nItems && cache.items.length !== cache.totalitems) {
                    this._invalidateCacheFor(id);

                    cache = null;
                } // if we have sth in the cache, theres nothing more to do, otherwise - request it.


                if (!cache) {
                    // first of all, save the request data
                    if (this._prepareRequest(id, startIdx, nItems)) {
                        this._sendRequest(id);
                    } // else no need to prefetch!

                } else {// it's cached already, nothing to do.
                }
            }

            /**
             * When a view is dismissed, it tells the loader to stop getting the info.
             * @param promise that is used to keep the connection between the view and the request
             * @param [mediaTypeDetails] optional media type details for stopping the request.
             */
            stopRequestFor(promise, mediaTypeDetails) {
                Debug.Media.Loader && console.log(this.name + ": stopRequestFor promise");

                this._rejectAndDeleteSpecificRequest(promise, MusicServerEnum.ReloadCause.STOPPED); // if this was the currently loading request -> proceed with other pending requests!


                if (this.currentRequest === NO_REQUEST_ID) {
                    Debug.Media.Loader && console.log(this.name + "    active request stopped!");

                    if (this.requestQueue.length > 0) {
                        Debug.Media.Loader && console.log(this.name + "    proceed with request from queue!");

                        this._sendRequest(this.requestQueue.shift());
                    }
                }
            }

            /**
             * Rejects and deletes all requests of this loader instance.
             * @param reason    why it was rejected, will be used in the cancel fn of the promise.
             */
            rejectAndDeleteAllRequests(reason) {
                Debug.Media.Loader && console.log(this.name + ": rejectAndDeleteAllRequests: " + reason);
                var allKeys = JSON.parse(JSON.stringify(Object.keys(this.requests)));

                for (var i = 0; i < allKeys.length; i++) {
                    this._rejectAndDeleteRequest(allKeys[i], reason);
                }
            }

            /**
             * Delegates that are registered by this method will receive an event once the mediaLoader updates
             * the contentOld, so the items dispatched earlier become invalid and the whole content needs to be reloaded.
             * @param delegate the method that will be called once a reload is neccessary
             * @returns {number}
             */
            registerForReloadEvent(delegate) {
                Debug.Media.Loader && console.log(this.name + ": registerForReloadEvent");

                if (!this.reloadDelegates) {
                    this.reloadDelegates = {};
                } // check cntr max


                if (this.registerCntr > 1024) {
                    this.registerCntr = 0;
                } // prepare cntr for register id.


                var regId = this.registerCntr++;
                this.reloadDelegates[regId] = delegate;
                return regId;
            }

            /**
             * Returns the current set of reload delegates registered for this extension.
             * @return {{}|*}
             */
            getReloadDelegates() {
                return this.reloadDelegates;
            }

            /**
             * Removes the reloadDelegates registered via registerForReloadEvent
             * @param regId the regId which was returned by registerForReloadEvent
             */
            unregisterFromReloadEvent(regId) {
                Debug.Media.Loader && console.log(this.name + ": unregisterFromReloadEvent");

                if (this.reloadDelegates && this.reloadDelegates.hasOwnProperty(regId)) {
                    delete this.reloadDelegates[regId];
                }
            }

            /**
             * Return all data that has currently been loaded.
             * @param id
             * @param mediaTypeDetails
             */
            getCurrentContent(id, mediaTypeDetails) {
                Debug.Media.Loader && console.log(this.name + ": getCurrentContent");
                return this._getFromCache(id);
            }

            /**
             * When data is modified on the client it can update the content stored in the loader to avoid having
             * to reload it after each modification.
             * @param id
             * @param mediaTypeDetails
             * @param newContent        the modified content object that will update the cached data.
             * @param [reason]          if given a reload event is fired, along with a reason
             */
            updateContent(id, mediaTypeDetails, newContent, reason) {
                Debug.Media.Loader && console.log(this.name + ": updateContent");

                this._storeInCache(newContent, true); // replace the current content.


                reason && this._dispatchReloadEvent(reason, null, id);
            }

            // --------------------------------------------------------------------------------
            // --------------------------------------------------------------------------------
            //           PRIVATE
            // --------------------------------------------------------------------------------
            // --------------------------------------------------------------------------------
            _sendRequest(id, mediaTypeDetails) {
                if (this.currentRequest === NO_REQUEST_ID) {
                    // no current requests pending, fire away
                    this.currentRequest = id;
                    Debug.Media.Loader && console.log(this.name + ": _sendRequest: " + id);

                    var request = this._getRequest(id),
                        aqCmd = this.getAcquireCommand(mediaTypeDetails);

                    if (request) {
                        if (aqCmd === false) {
                            this._handleResultErrorReceived(null, {
                                reason: "Precondition Failed for " + this.name
                            });
                        } else {
                            request.command = this._send(aqCmd, this.getAcquireCommandArguments(request)); // ensure the loader won't get "stuck" if this request fails and will never respond.

                            this._startResponseTimeout(request.command);
                        }
                    }
                } else {
                    Debug.Media.Loader && console.log(this.name + ": _sendRequest: put " + id + " into the queue. currentRequest: " + this.currentRequest); // there is a current request.

                    this.requestQueue.unshift(id);
                }
            }

            _send(command, args) {
                var fullCmd = "audio/cfg/" + command + "/" + args;
                Debug.Communication && CommTracker.commStart(CommTracker.Transport.AUDIO_SOCKET, fullCmd);
                this.channel.emit(this.component.ECEvent.SendMessage, {
                    cmd: fullCmd
                });
                return fullCmd;
            }

            /**
             * Returns all there is inside the cache for this identifier.
             * @param id the identifier of the item we want the cached content for
             * @returns {*} either null or the result that we've got in the cache
             */
            _getFromCache(id) {
                var cacheResult = null;
                
                if (this.cache.hasOwnProperty(id)) {
                    cacheResult = this.cache[id];
                } else {
                    // check if there is a cache entry with the same id but different nItems
                    const idParts = id.toString().split(QUEUE_SEPARATOR);
                    const idWithoutNItems = idParts[0];
                    const cacheKeys = Object.keys(this.cache);
                    for (let i = 0; i < cacheKeys.length; i++) {
                        const cacheKey = cacheKeys[i];
                        const cacheKeyParts = cacheKey.split(QUEUE_SEPARATOR);
                        if (cacheKeyParts[0] === idWithoutNItems) {
                            cacheResult = this.cache[cacheKey];
                            break;
                        }
                    }
                }

                return cacheResult;
            }

            /**
             * Used to look for the parent(s) of the id provided in the cache. Used to invalidate those too, as e.g.
             * when a spotify song is unfollowed, it should no longer remain in the songs-list and a reload event for
             * that id is to be fired too.
             * @param containedId
             * @returns {*[]}
             * @private
             */
            _lookupParentsFromCache(containedId) {
                var parentIds = [],
                    browsable;
                Object.keys(this.cache).forEach(browsableKey => {
                    browsable = this.cache[browsableKey];
                    browsable.items && browsable.items.some(containedItem => {
                        if (containedItem.id === containedId || containedItem.audiopath === containedId) {
                            parentIds.push(browsableKey);
                        }
                    });
                });
                return parentIds;
            }

            /**
             * Trys to store the given resultObj in the cache, not caring whether we requested it or if we
             * have received it, because we're connected via the Miniserver.
             * @param resultObject
             * @param replace       this result object is to be used to overwrite the cache, not to extend an existing cache.
             * @returns {boolean}
             */
            _storeInCache(resultObject, replace) {
                var request = this._getRequest(resultObject.queueId);

                if (request === null && !replace) {
                    // --> request may be null if we received a reload event due to changes eg. on a playlist
                    console.warn("Received a result without a request, ignore it!");
                    return false;
                } else if (request && resultObject.start !== request.start) {
                    console.error("Start Indices missmatch! should have been handled before trying to cache it!");
                    return false;
                }

                var start = resultObject.start;
                var wasCached = false;
                var cacheFailReason = null;

                if (replace) {
                    // we simply replace it, no matter what.
                    this.cache["" + resultObject.queueId + ""] = resultObject;
                    wasCached = true;
                } else if (this.cache.hasOwnProperty(resultObject.queueId)) {
                    // check if we've got sth in our cache
                    // yes it's cached, merge!
                    var cachedObj = this.cache[resultObject.queueId];

                    if (cachedObj.totalitems !== resultObject.totalitems) {
                        console.warn("The totalItems count has changed! from " + cachedObj.totalitems + " to " + resultObject.totalitems);
                        cachedObj.totalitems = resultObject.totalitems;
                    }

                    if (start === cachedObj.items.length) {
                        // add them
                        cachedObj.items = cachedObj.items.concat(resultObject.items);
                        wasCached = true;
                    } else if (start < cachedObj.items.length) {
                        // insert them
                        for (var i = 0; i < resultObject.items.length; i++) {
                            cachedObj.items[resultObject.start + i] = resultObject.items[i];
                        }

                        wasCached = true;

                        if (cachedObj.totalitems !== resultObject.totalitems) {
                            cachedObj.totalitems = resultObject.totalitems;
                        }
                    } else {
                        cacheFailReason = "There is missing content between our cache and this response! " + "response starts with index " + resultObject.start + ", the " + "cache contains " + cachedObj.items.length + " items at the moment.";
                    }
                } else if (start === 0) {
                    // no it's new, store it (only if startIdx is 0)
                    this.cache["" + resultObject.queueId + ""] = resultObject;
                    Debug.Media.Loader && console.log(this.name + ' did cache the content of "' + (resultObject.name || resultObject.queueId) + '"');
                    wasCached = true;
                }

                if (!wasCached && request) {
                    console.error("Caching for " + resultObject.name + " failed - " + cacheFailReason);
                } else if (!wasCached) {
                    Debug.Media.Loader && console.log("Caching a randomly received response (via Miniserver) didn't work - " + cacheFailReason);
                }

                return wasCached;
            }

            /**
             * Used to keep track of what was already requested from the server
             * @param id the id of the media-library-item whos content was requested
             * @param start the start index from which on nItems were requested
             * @param nItems the number of items starting from start that were requested
             * @param [deferred] deferred object if there is one
             * @param [limited] if true, the download will stop after nItems have been provided.
             * @returns {boolean} false if there's already a request for this control.
             * @private
             */
            _prepareRequest(id, queueId, start, nItems, deferred, limited) {
                Debug.Media.Loader && console.log(this.name + ": _prepareRequest: id " + id + ", start: " + start + ", nItems: " + nItems);
                var newRequest = true;

                if (this.requests.hasOwnProperty(queueId)) {
                    Debug.Media.Loader && console.log(this.name + ":    request already exists.", this.requests[queueId]);

                    if (this.requests[queueId].hasOwnProperty("maxItems") && !limited) {
                        delete this.requests[queueId].maxItems; // delete it, as from now an all content is to be loaded
                    }

                    newRequest = false; // request already exists


                    if (deferred) {
                        Debug.Media.Loader && console.log(this.name + ":    add deferred to existing request");
                        if (this.requests[queueId].deferreds) {
                            this.requests[queueId].deferreds.push(deferred);
                        } else {
                            this.requests[queueId].deferreds = [deferred];
                        }
                    } // update nItems for next download


                    this.requests[queueId].nItems = nItems;
                } else {
                    Debug.Media.Loader && console.log(this.name + ":    new request!");
                    this.requests[queueId] = {
                        id: id,
                        queueId,
                        start: start,
                        nItems: nItems
                    };

                    if (limited) {
                        this.requests[queueId].maxItems = nItems;
                    }

                    if (deferred) {
                        this.requests[queueId].deferreds = [deferred];
                    }
                }

                return newRequest;
            }

            /**
             * Removes the request from the request-cache, returns null if sth goes wrong
             * @param id the id of the item who's content was accessed by the request
             * @returns {*} either the request or null
             * @private
             */
            _popRequest(id) {
                if (!this.requests.hasOwnProperty(id)) {
                    console.warn("Request for " + id + " was tried to be accessed, but isn't stored anymore");
                    return null;
                }

                var request = this.requests[id];
                delete this.requests[id];
                return request;
            }

            /**
             * Simply returns the request matching the id
             * @param id the identifier for the media item whos content is to be fetched
             * @returns {*} either NULL or the request.
             * @private
             */
            _getRequest(id) {
                if (!this.requests.hasOwnProperty(id)) {
                    return null;
                }

                return this.requests[id];
            }

            /**
             * Takes care of everything there
             * @param request
             * @param cacheResult
             * @private
             */
            _handleUnfinishedRequest(request, cacheResult) {
                var missingStartIndex = cacheResult.items.length; // download all at once now.

                request.start = missingStartIndex; // puts this request to the top of the queue or sends it right away

                this._sendRequest(request.queueId);
            }

            /**
             * When a request is rejected, it not only has to be removed from the queue, it also has to be deleted from
             * our set of requests.
             * @param rqId      the id of the request to reject and remove
             * @param reason    why was it rejected?
             * @private
             */
            _rejectAndDeleteRequest(rqId, reason) {
                Debug.Media.Loader && console.log(this.name + ": _rejectAndDeleteRequest: " + rqId + " - " + reason);

                if (this.requests.hasOwnProperty(rqId)) {
                    var rq = this.requests[rqId];

                    if (rq.deferreds) {
                        rq.deferreds.forEach(function (def) {
                            def.reject(reason);
                        });
                    }

                    delete rq.deferreds; // delete the request itself - it will prevent that the data that arrives after stopping will
                    // be cached, but makes it more stable against services that aren't working properly.

                    delete this.requests[rqId];

                    if ("" + this.currentRequest === rqId + "") {
                        // ensure either one is a string (don't mix up strings and numbers)
                        // the request being stopped is the current one, reset attribute.
                        this.currentRequest = NO_REQUEST_ID;
                    }
                }
            }

            _rejectAndDeleteSpecificRequest(promise, reason) {
                var allKeys = JSON.parse(JSON.stringify(Object.keys(this.requests))),
                    request,
                    wantedDef;
                Debug.Media.Loader && console.log(this.name, "_rejectAndDeleteSpecificRequest, reason: " + reason);

                for (var i = 0; i < allKeys.length; i++) {
                    request = this.requests[allKeys[i]];
                    wantedDef = request.deferreds.find(function (def) {
                        return def.promise === promise;
                    });

                    if (wantedDef) {
                        Debug.Media.Loader && console.log(this.name, "    found the defer to reject, rq stored with key: " + allKeys[i], request);
                        request.deferreds.splice(request.deferreds.indexOf(wantedDef), 1).forEach(function (def) {
                            def.reject(reason);
                        }.bind(this));

                        if (request.deferreds.length === 0) {
                            Debug.Media.Loader && console.log(this.name, "    this requests deferreds are now all gone, remove");
                            delete this.requests[allKeys[i]];

                            if ("" + this.currentRequest === allKeys[i] + "") {
                                // ensure either one is a string (don't mix up strings and numbers)
                                Debug.Media.Loader && console.log(this.name, "    it was the current request, move on"); // the request being stopped is the current one, reset attribute.

                                this.currentRequest = NO_REQUEST_ID;
                            }
                        } else {
                            Debug.Media.Loader && console.log(this.name, "    there are still deferreds left on this request");
                        }
                    } else {
                        Debug.Media.Loader && console.log(this.name, "    request with key: " + allKeys[i] + " hasn't got the promise who's request is stopped", request);
                    }
                }
            }

            /**
             * Will cancel the request (meaning we're no longer waiting for it), plus it will inform the requests
             * deferreds that this request has been rejected.
             * @param reason    the cause to pass along to the deferreds.
             * @private
             */
            _cancelCurrentRequest(reason) {
                var currRqObj = this._getRequest(this.currentRequest);

                if (currRqObj.deferreds) {
                    currRqObj.deferreds.forEach(function (def) {
                        def.reject(reason);
                    });
                }

                delete this.requests[this.currentRequest];
                this.currentRequest = NO_REQUEST_ID;
            }

            /**
             * Used e.g. inside the public invalidateContentCachesOf Method. Focuses on only one cache, does not
             * dispatch anything.
             * @param id    the id of the cache to remove.
             * @private
             */
            _invalidateCacheFor(id) {
                Debug.Media.Loader && console.log(this.name + ": _invalidateCacheFor " + id);

                for (const [key, value] of Object.entries(this.cache)) {
                    if (value.id === id) {
                        delete this.cache[key];
                        break;
                    }
                }
            }

            // ------------------------- Request Timeout Handling -------------------------

            /**
             * launch a timeout, so that if nothing ever is going to respond, the request is cancelled
             * @param cmd
             * @private
             */
            _startResponseTimeout(cmd) {
                this._responseTimeout = setTimeout(function () {
                    console.error(this.name + " response for cmd " + cmd + " timed out!");

                    this._clearResponseTimeout();

                    this._handleResultErrorReceived(null, {
                        command: cmd,
                        reason: TIMEOUT_REASON
                    });
                }.bind(this), REQUEST_TIMEOUT * requestRetryCounter); // exponential back off, the request might succeed if we wait longer.
            }

            /**
             * Stops and nulls the response timeout, optionally also resets the retry counter
             * @param success       if true, the retry counter is reset.
             * @private
             */
            _clearResponseTimeout(success) {
                this._responseTimeout && clearTimeout(this._responseTimeout);
                this._responseTimeout = null;

                if (success) {
                    requestRetryCounter = 1;
                }
            }

            // ------------------------------------------------------------------------------------------------
            // ------------------------------------------------------------------------------------------------
            //                   Events & Results
            // ------------------------------------------------------------------------------------------------
            // ------------------------------------------------------------------------------------------------
            _doesHandleResult(result) {
                var doHandle = false;

                if (result.hasOwnProperty("oldCommand")) {
                    doHandle = result.oldCommand === this.getAcquireCommand(null, true);
                }

                if (!doHandle && result.hasOwnProperty("command")) {
                    // test new mapping handling
                    var lastRqObj = this.requests[this.currentRequest];

                    if (lastRqObj && result.command === lastRqObj.command) {
                        doHandle = true;
                    }
                }

                return doHandle;
            }

            _processResult(data, cmd, fullCmd) {
                Debug.Media.Loader && console.log(this.name + ": _processResult of " + fullCmd); // answer received, reset timeout

                this._clearResponseTimeout(true); // first of all make sure the result we're processing is from a request we're waiting for.

                if (!this._getIsCurrentRequest(data)) {
                    console.warn(this.name, "Ignoring either outdated result or one that hasn't been requested by this loader!");
                    return;
                } // check if we've got to dispatch this to someone

                var request = this._getRequest(data.queueId);

                var requestFinished = true;
                var isOverflowRequested = data.totalitems < request.nItems; // if the totalitems is less than the requested items, we're done.
                if (isOverflowRequested) {
                    requestFinished = true;
                    this._dispatchResult(request.deferreds, data, requestFinished);
                }

                var wasCached; // first of all, try to cache the result
                
                if (request.hasOwnProperty("maxItems") && data.items.length > 0) {
                    // limited requests mustn't be used to extend any data. e.g. if the order of the items did change
                    // and only the first 10 items are used to "extend" the content (the first 10 items are replaced)
                    // -> this will cause inconsistency
                    wasCached = this._storeInCache(data, true); // limited requests mustn't
                } else {
                    wasCached = this._storeInCache(data);
                }

                if (!request) {
                    console.error("Cannot process the result of an request we did not launch!");
                    throw "Cannot process the result of an request we did not launch!";
                }

                if (request.deferreds && wasCached && !isOverflowRequested) {
                    // check if the request is finished
                    var cacheResult = this._getFromCache(data.queueId);

                    requestFinished = cacheResult.items.length === cacheResult.totalitems;

                    if (cacheResult.items.length > cacheResult.totalitems) {
                        console.error("More items where returned than there are listet in totalitems! Got " + cacheResult.items.length + " but totalItems says " + cacheResult.totalitems, cacheResult);
                        requestFinished = true;
                    }

                    if (request.hasOwnProperty("maxItems")) {
                        requestFinished = requestFinished || cacheResult.items.length >= request.maxItems;
                    } // keep track of retries where the start index does not change - we could run into an endless loop!


                    if (request.start === cacheResult.items.length) {
                        Debug.Media.Loader && console.warn("The requests start index did not change, there might be an issue with the ID " + request.id);

                        if (this.retryCount++ > 3) {
                            // e.g. in Spotify albums Music Servers up to early 2017 did deliver 50 items max, even
                            // tough there might be more items. Totalitems is properly set. Needs to be handled in a
                            // music server update.
                            console.error("The server failed to deliver missing data after " + this.retryCount + " attempts. Adopting totalitems to avoid loop!");
                            data.totalitems = cacheResult.items.length;
                            requestFinished = true;
                        }
                    } else {
                        // reset the retry
                        this.retryCount = 0;
                    } // the ui can now handle partial results

                    if(data.items.length > 0 && data.items.length <= data.totalitems) {
                        this._dispatchResult(request.deferreds, data, requestFinished);
                    } else {
                        requestFinished = true;
                        this._dispatchResult(request.deferreds, cacheResult, requestFinished);
                    }

                    if (!requestFinished) {
                        Debug.Media.Loader && console.log("     request not finished yet, proceed.");

                        this._handleUnfinishedRequest(request, cacheResult);
                    }
                } else {
                    Debug.Media.Loader && console.log("     not dispatching anything. wasCached: " + wasCached);
                    Debug.Media.Loader && console.log("     number of deferreds: " + (!request.deferreds ? 0 : request.deferreds.length));
                } // if we're finished and we've got a request, remove it.


                if (request) {
                    if (requestFinished) {
                        Debug.Media.Loader && console.log("     request finished.");

                        this._popRequest(request.queueId);
                    }

                    this.currentRequest = NO_REQUEST_ID;

                    if (this.requestQueue.length > 0) {
                        Debug.Media.Loader && console.log("     continue with next request."); // call sendRequest with the next ID.

                        this._sendRequest(this.requestQueue.shift());
                    }
                }
            }

            /**
             * Verifies that the resultObject received is of any relevance for the loader right now. There may be
             * results of earlier requests that have been cancelled. There may even be results incoming that are for
             * the same ID as the current request, but the start indices may not match.
             * @param data
             * @returns {boolean|*}
             * @private
             */
            _getIsCurrentRequest(data) {
                var request = this._getRequest(data.queueId),
                    isCurrent; // it may be the same id, but if the start doesn't match it might be an outdated one.


                isCurrent = !!request && data.start === request.start;
                return isCurrent;
            }

            _doesHandleEvent(event) {
                return false;
            }

            _processEvent(event) {
                console.warn(this.name + ": _processEvent of MediaLoaderExt called, did you forget to overwrite it?");
            }

            /**
             * This method is used to deal with errors that occurred during the current media request. After this method
             * the current request has already been rejected. after this method the next request from the queue will be
             * processed.
             * @param error         the error that has occurred
             * @returns {boolean}   whether or not the error has already been processed
             * @private
             */
            _processRequestError(error) {
                return false;
            }

            // ------------------------------------------------------------------------------------------------
            // ------------------------------------------------------------------------------------------------
            //                   Dispatching
            // ------------------------------------------------------------------------------------------------
            // ------------------------------------------------------------------------------------------------

            /**
             * This method is used to intercept item packages before they are dispatched.
             * since 20th of october, at least all spotify name-items are encoded. To ensure compatibility all items
             * are decoded, if possible.
             * @param result the result to adopt
             * @param finished whether or not this is the final result
             * @returns {*} the adopted result
             */
            prepareResult(result, finished) {
                result.items.forEach(this.component.decodeItem.bind(this.component));
                return result;
            }

            _dispatchResult(deferreds, result, finished) {
                Debug.Media.Loader && console.log(this.name + ": _dispatchResult");
                var dispatchNow = this.dispatchQueue.length === 0;

                this._enqueuePackage(deferreds, result, finished);

                if (dispatchNow) {
                    Debug.Media.Loader && console.log("    dispatching now");

                    this._dispatchFromQueue();
                } else {
                    Debug.Media.Loader && console.log("    dispatching after the previously queued packets are sent");
                }
            }

            // never dispatch all at once - it corrupts the UI

            /**
             * Splits the cache into pieces and dispatches it. It will dispatch packets with nItems each.
             * @param deferred
             * @param cache     the cached data list
             * @param finished  whether or not the download is finished yet.
             * @param nItems    how large the chunks ought to be
             * @params [limited] if true, only the first nItems are dispatched
             * @returns {*}
             * @private
             */
            _splitAndDispatchSeparated(deferred, cache, finished, nItems, limited) {
                Debug.Media.Loader && console.log(this.name + ": _splitAndDispatchSeparated");
                var returnRightAway = {},
                    nxtPacket,
                    start = 0;
                returnRightAway.start = start;
                returnRightAway.id = cache.id;
                returnRightAway.totalitems = cache.totalitems;
                returnRightAway.name = cache.name;
                returnRightAway.items = cache.items.slice(start, nItems); // update start

                start += nItems; // since we have a new and faster way of handling all this, don't dispatch packet by packet, but one small packet
                // initially and then all of the rest

                if (start < cache.items.length && !limited) {
                    nxtPacket = {};
                    nxtPacket.start = start;
                    nxtPacket.id = cache.id;
                    nxtPacket.totalitems = cache.totalitems;

                    if (cache.hasOwnProperty("name")) {
                        nxtPacket.name = cache.name;
                    }

                    if (finished) {
                        nxtPacket.start = 0;
                        nxtPacket.items = cache.items;
                    } else {
                        nxtPacket.items = cache.items.slice(start, cache.items.length);
                    }

                    this._enqueuePackage([deferred], nxtPacket, finished); // intermediary packets must not be finished

                }

                if (this.dispatchQueue.length > 0) {
                    // reset the last packet's isFinished to the value that was passed into this method.
                    this.dispatchQueue[this.dispatchQueue.length - 1].isFinished = finished;
                    this.dispatchQueueTimeout = setTimeout(this._dispatchFromQueue.bind(this), 200);
                }

                if (finished) {
                    // the cached package already contains all data there is. Resolve it.
                    this._enqueuePackage([deferred], returnRightAway, true);

                    returnRightAway = null;
                    this.dispatchQueueTimeout = setTimeout(this._dispatchFromQueue.bind(this), 0);
                }

                return returnRightAway;
            }

            _enqueuePackage(deferreds, pack, finished) {
                pack.isFinished = finished;
                pack.trgtDeferreds = deferreds;
                this.dispatchQueue.push(pack);
            }

            /**
             * Will check the queue if there are packets to dispatch. if so, it'll dispatch
             * a packet an then it'll launch a timer that will place a recursive call for others
             * @private
             */
            _dispatchFromQueue() {
                var pack, deferreds, finished, result;
                this.dispatchQueueTimeout = null;

                if (this.dispatchQueue.length === 0) {
                    return;
                }

                pack = this.dispatchQueue.splice(0, 1)[0];
                deferreds = pack.trgtDeferreds;
                finished = pack.isFinished;
                result = this.prepareResult(pack, finished);

                if (finished) {
                    deferreds.forEach(function (def) {
                        def.resolve(result);
                    });
                } else {
                    deferreds.forEach(function (def) {
                        def.notify(result);
                    });
                }

                if (this.dispatchQueue.length > 0) {
                    // recursive call to dispatch packets from the queue.
                    this.dispatchQueueTimeout = setTimeout(this._dispatchFromQueue.bind(this), 200);
                }
            }

            /**
             * Will inform all registered listeners that something occurred that will trigger a reload. Can also tell
             * the view to dismiss itself and all parent views of the same type.
             * @param reason    What did trigger the reload event?
             * @param remove    // don't bother reloading, remove the screen (e.g. Service Account removed)
             * @param id        The id of the item affected causing the reload event.
             * @private
             */
            _dispatchReloadEvent(reason, remove, id) {
                if (this.reloadDelegates) {
                    var registeredKeys = Object.keys(this.reloadDelegates);
                    var i;

                    for (i = 0; i <= registeredKeys.length - 1; i++) {
                        try {
                            var delegateId = registeredKeys[i];

                            if (this.reloadDelegates.hasOwnProperty(delegateId)) {
                                this.reloadDelegates[delegateId](reason, remove, id); // calls the reload events.
                            } else {// view might already have been dismissed & unregistered!
                            }
                        } catch (exc) {
                            console.error("Error while dispatching the reloadEvents!");
                        }
                    }
                }
            }

        }

        Components.Audioserver.extensions.MediaLoaderBase = MediaLoaderBase;
    }
    return Components;
}(window.Components || {});
