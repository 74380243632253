window.Components = function (Components) {
    class MediaSocket extends Components.Extension {
        //region Private
        MAX_RETRY = 2; //endregion Private

        //region Getter
        get isOpen() {
            return this._isOpen;
        }

        get isAuthenticated() {
            return this._isAuthenticated;
        } //endregion Getter


        //region Setter
        set isOpen(newVal) {
            Debug.Media.Comm && console.log(this.name, "isOpen = " + !!newVal);
            this._isOpen = !!newVal;

            if (!this._isOpen) {
                this.isAuthenticated = false;
            }
        }

        set isAuthenticated(newVal) {
            Debug.Media.Comm && console.log(this.name, "isAuthenticated = " + !!newVal);
            this._isAuthenticated = !!newVal;
        } //endregion Setter


        constructor(component, extensionChannel) {
            super(...arguments);
            this._rndId = getRandomIntInclusive(100, 999);
            this.name += this._rndId;
            this.isAuthenticated = false;
            this.channel.on(this.component.ECEvent.DidAuthenticate, function () {
                this.isAuthenticated = true;
            }.bind(this));
            this.channel.on(this.component.ECEvent.DidReceiveAuthError, function (event, err) {
                this._onError(err);
            }.bind(this));
            this._socketOpenCalls = 0;
            this._socketCloseCalls = 0;
            this._isP2PConnection = true;
        }

        // ------------------------------------------------------------------------
        // Public Methods
        // ------------------------------------------------------------------------
        connect(mediaServer, mediaConnectionOpenTiming) {
            this._mediaConnectionOpenTiming = mediaConnectionOpenTiming;
            Debug.Media.Socket && console.log(this.name, "connect: " + mediaServer.name + " @ " + mediaServer.host, getStackObj());
            this.shouldBeConnected = true;
            this.mediaServer = mediaServer;
            this.reconnectCnt = 0;

            this._connectSocket();
        }

        disconnect() {
            Debug.Media.Socket && console.log(this.name, "disconnect.");
            this.shouldBeConnected = false;

            if (this.reconnectTimer) {
                Debug.Media.Socket && console.log("     a reconnect timer is active, stop it");
                clearTimeout(this.reconnectTimer);
                this.reconnectTimer = null;
            }

            this._closeSocket();

            this.channel.emit(this.component.ECEvent.ConnClosed);
        }

        sendCommand(cmdObj) {
            var command = cmdObj.cmd;
            Debug.Media.Comm && debugLog(this.name, "sendCommand '" + JSON.stringify(command) + "'");

            if (this.isOpen) {
                if (this.isAuthenticated || this._cmdNeedsNoAuth(command)) {
                    // emit a messageSent event, this way e.g. the MessageParser is able to prepare for incoming responses.
                    this.channel.emit(this.component.ECEvent.MessageSent, cmdObj);

                    if (Debug.Communication) {
                        if (!CommTracker.commSent(CommTracker.Transport.AUDIO_SOCKET, cmdObj.cmd)) {
                            console.warn(this.name, "No tracking for command: " + cmdObj.cmd, cmdObj, getStackObj());
                        }
                    }

                    this.socket.send(command);
                } else {
                    Debug.Media.Comm && console.log(this.name, "sendCommand - enqueue, not ready: " + command);

                    if (!cmdObj.hasOwnProperty("queueCount")) {
                        cmdObj.queueCount = 0;
                    }

                    cmdObj.queueCount++;

                    if (cmdObj.queueCount >= 10) {
                        console.warn(this.name, "---------------------------------------------------------------");
                        console.warn(this.name, "---------------------------------------------------------------");
                        console.warn(this.name, "   LOOP while sending " + command + " detected, bail out");
                        console.warn(this.name, "---------------------------------------------------------------");
                        console.warn(this.name, "---------------------------------------------------------------");
                        return;
                    }

                    this.component.connectionPromise().done(function () {
                        this.sendCommand(cmdObj);
                    }.bind(this));
                }
            } else {
                console.error('Socket not open yet, command "' + command + '" not sent.');
            }
        }

        didReceiveGreeting() {
            if(Debug.Communication) {
                const elapsed = timingDelta(this._authRequiredTiming);
                console.warn("=".repeat(50));
                console.info(this.name + "  ", "open -> didReceiveGreeting: " + elapsed + "ms");
                console.warn("=".repeat(50));
            }
            this.channel.emit(this.component.ECEvent.AuthenticationChallenge);
        }

        // ------------------------------------------------------------------------
        // Socket Handling
        // ------------------------------------------------------------------------
        _closeSocket() {
            if (!this.socket) {
                return;
            }

            this._socketCloseCalls++;
            Debug.Media.Socket && console.log(this.name + ": _closeSocket. (O:" + this._socketOpenCalls + "/C:" + this._socketCloseCalls + ")");
            Debug.Media.Comm && console.log(this.name + ": _closeSocket. (O:" + this._socketOpenCalls + "/C:" + this._socketCloseCalls + ")");

            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
                this.reconnectTimer = null;
            }

            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onerror = null;
            this.socket.onmessage = null;
            this.socket.close();
            this.socket = null;
        }

        _connectSocket() {
            if (this.socket) {
                Debug.Media.Socket && console.error("Attempted to open a connection, whilst another socket is still around!");

                this._closeSocket();
            }

            try {
                this._socketOpenCalls++;
                this.socket = this._getSocket();
                (Debug.Media.Socket || Debug.Media.Comm) && console.log(this.name + ": _connectSocket. (O:" + this._socketOpenCalls + "/C:" + this._socketCloseCalls + ") - " + this.socket.url);
                this.socket.onopen = this._onOpen.bind(this);
                this.socket.onclose = this._onClose.bind(this);
                this.socket.onerror = this._onError.bind(this);
                this.socket.onmessage = this._onMessage.bind(this);
            } catch (exc) {
                console.error("Error while opening the socket!", exc);
                this.channel.emit(this.component.ECEvent.ConnClosed);
                this.channel.emit(this.component.ECEvent.NotReachable); // never handled
            }
        }

        _getSocket() {
            var host = this._getWebSocketHost();

            this.name = this._getDebugName(host);
            Debug.Media.Socket && console.log(this.name, "getSocket: " + host);
            return new WebSocket(this.getWebsocketProtocol() + host, 'remotecontrol');
        }

        _getDebugName(host) {
            return "MediaSocket" + this._rndId + "@" + host;
        }

        _getWebSocketHost() {
            var reachMode = CommunicationComponent.getCurrentReachMode(),
                { states: { host: resolvedHost } } = SandboxComponent.getStatesForUUID(this.mediaServer.uuidAction)

            if (this.reconnectCnt >= this.MAX_RETRY) {
                this._isP2PConnection = true;
            } else {
                // Miniserver Gen. 1
                // • Local:
                //     • App <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use the Musicserver Gen. 2 IP:PORT and MAC for dynDNS URL
                //             • Prefer SSL over Plan, but fallback to Plain
                // • Remote:
                //     • App <=> Miniserver Gen.1 (Proxy) <=> Musicserver Gen. 2
                //         • Plain (The Gen. 1 Miniserver is only capable of Plain traffic)
                //             • Use Miniserver Gen. 2 Connection URL + /proxy/{MUSIC_SERVER_UUID}
                // Miniserver Gen. 2 = wss:// -> Fallback auf ws://
                // • Local:
                //     • App <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use the Musicserver Gen. 2 IP:PORT and MAC for dynDNS URL
                //             • Prefer SSL over Plan, but fallback to Plain
                // • Remote:
                //     • App <=> Miniserver Gen. 2 (Proxy) <=> Musicserver Gen. 2
                //         • SSL | Plain
                //             • Use Miniserver Gen. 2 Connection URL + /proxy/{MUSIC_SERVER_UUID}
                //             • Prefer SSL over Plan, but fallback to Plain
                if (!(reachMode === ReachMode.LOCAL && this.reconnectCnt === 0)) {
                    resolvedHost = ActiveMSComponent.getMiniserverConnectionUrl().replace("http://", "").replace("https://", "") + "/proxy/" + this.mediaServer.uuidAction + "/";
                    this._isP2PConnection = false;
                }
            }
            return resolvedHost;
        }

        getWebsocketProtocol() {
            // BG-I14747 --> for a secure websocket a separate class is in charge! MediaSocketSecureExt, don't offload it to this one.
            // E.g. a Miniserver Gen 2 like the usdemominiserver cannot use wss as it's reachable via usdemominiserver.loxone.com:7785 instead of RC directly.
            // It has a valid remote connect certificate, but still it cannot connect with it.
            // Unclear why the following issues did fail earlier: BG-I11810, BG-I11263: Couldn't establish a connection to the AudioServer when using RemoteConnect when saving into the Miniserver
            return "ws://";
        }

        // ------------------------------------------------------------------------
        // Socket Callbacks
        // ------------------------------------------------------------------------
        _onOpen() {
            if (Debug.Communication) {
                const elapsed = timingDelta(this._mediaConnectionOpenTiming);
                console.warn("=".repeat(50));
                console.info(this.name + "  ", "states -> mediaConnectionOpenTiming: " + elapsed + "ms");
                console.warn("=".repeat(50));
                this._authRequiredTiming = timingNow();
            }

            this.component.setConnectionUrl(this.socket.url.replace("ws://", "http://").replace("wss://", "https://"));
            this.component.setIsP2PConnection(this._isP2PConnection);
            this.reconnectCnt = 0;
            Debug.Media.Socket && console.log(this.name, "onOpen");
        }

        _onClose(arg) {
            Debug.Media.Socket && console.log(this.name, "onClose");
            this.isOpen = false;
            this.channel.emit(this.component.ECEvent.ConnClosed);

            this._closeSocket();

            if (!this.shouldBeConnected) {
                return;
            }

            if (arg.wasClean) {
                // closed regularily - open again
                this._connectSocket();
            } else {
                Debug.Media.Socket && console.error("Socket was not clean when closed! " + arg.reason + ". Trying again later"); // sth is wrong with the server, try again in a few secs.

                this.reconnectTimer = setTimeout(function () {
                    if (!this.shouldBeConnected) {
                        Debug.Media.Comm && console.error("Reconnect: Timer fired, but there shouldn't be a connection now. so don't reconnect");
                    } else {
                        Debug.Media.Comm && console.error("Reconnect: Try again, socket wasn't clean when closed!");

                        if (this.reconnectCnt === this.MAX_RETRY) {
                            this.reconnectCnt = 0;
                        } else {
                            this.reconnectCnt += 1;
                        }

                        this._connectSocket();
                    }

                    this.reconnectTimer = null;
                }.bind(this), 5000);
            }
        }

        _onError(err) {
            this.isOpen = false;
            console.error(this.name, "onError " + JSON.stringify(err));

            this._closeSocket();

            this.component.emit(this.component.ECEvent.ConnClosed, err); // so everyone knows, e.g. mediaMessageParser needs to reset the initial message
            // after closeSocket the "onClose" event won't arrive, so respond now

            this.reconnectTimer = setTimeout(function () {
                console.error(this.name, "OnError: reconnect timer fired!");

                if (this.reconnectCnt === this.MAX_RETRY) {
                    this.reconnectCnt = 0;
                } else {
                    this.reconnectCnt += 1;
                }

                this._connectSocket();
            }.bind(this), 500);
        }

        _onMessage(msg) {
            Debug.Media.CommDetailed && debugLog(this.name + ": Received: " + JSON.stringify(msg.data));

            if (!Debug.Media.CommDetailed && Debug.Media.Comm) {
                var text = JSON.stringify(msg.data);
                var short = text.substring(0, 40) + " ... " + text.substring(text.length - 60, text.length - 1);
                console.info(this.name + ": Received: " + short);
            }

            try {
                if (msg.type === "message") {
                    // Wait for the Websocket greeting before we can send commands! (this.isOpen)
                    if (msg.data.indexOf(MusicServerEnum.Comm.ROOT_MSG) === 0) {
                        this.isOpen = true;
                    }

                    this.channel.emit(this.component.ECEvent.MessageReceived, msg.data);

                    if (msg.data.indexOf(MusicServerEnum.Comm.ROOT_MSG) === 0) {
                        this.didReceiveGreeting();
                    }
                }
            } catch (exc) {
                console.error(exc.stack);
                console.error("Message could not be parsed! ", msg.data);
            }
        }

        // ------------------------------------------------------------------------
        // Private Methods
        // ------------------------------------------------------------------------
        _dispatchEvent(eventIdentifier, payload) {
            // for each entry in the eventData-Array, dispatch an event
            for (var i = 0; i < payload.length; i++) {
                var eventData = payload[i];
                var obj = {};
                obj[eventIdentifier] = eventData;
                obj.source = getStackObj();
                this.channel.emit(this.component.ECEvent.EventReceived, obj);
            }
        }

        _cmdNeedsNoAuth(cmd) {
            return cmd.startsWith("secure/authenticate") || cmd.startsWith("audio/cfg/getkey");
        }

        _isGen1Miniserver() {
            var isGen1 = false;

            switch (ActiveMSComponent.getMiniserverType()) {
                case MiniserverType.MINISERVER:
                case MiniserverType.MINISERVER_GO:
                    isGen1 = true;
                    break;
            }

            return isGen1;
        }

    }

    if (!("Audioserver" in Components)) {
        Components.Audioserver = {};
    }
    if (!("extensions" in Components.Audioserver)) {
        Components.Audioserver.extensions = {};
    }
    Components.Audioserver.extensions.MediaSocket = MediaSocket;
    return Components;
}(window.Components || {});
